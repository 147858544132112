var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.accion)+" Solicitud: "),(_vm.id)?_c('b',[_vm._v(_vm._s(_vm.id))]):_vm._e()])]),_c('div',{staticClass:"col-md-1"},[(
                  _vm.$store.getters.can(
                    'hidrocarburos.adicionalesSolicitudes.create'
                  ) &&
                    _vm.form.estado == 2 &&
                    _vm.val_fecha
                )?_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"data-toggle":"modal","data-target":"#Modal_adicional"},on:{"click":function($event){return _vm.$refs.compSolicitudAdicional.init(false)}}},[_c('small',[_vm._v("Ajuste Cargue")])]):_vm._e()]),_c('div',{staticClass:"col-md-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Hidrocarburos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Procesos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Solicitudes")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{class:_vm.accion == 'Editar' ? 'col-md-6' : 'col-md-12'},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Sitio")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                            _vm.$v.sitio.$invalid ? 'is-invalid' : 'is-valid',
                            _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                          ],attrs:{"label":"nombre","options":_vm.listasForms.sitios,"placeholder":"Sitio","disabled":_vm.form.estado == 2},on:{"input":function($event){return _vm.getSelectSitio()}},model:{value:(_vm.sitio),callback:function ($$v) {_vm.sitio=$$v},expression:"sitio"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Solicitud")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha),expression:"form.fecha"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"date","disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.fecha)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha", $event.target.value)},function($event){return _vm.validaLimiteSolicitud()}]}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Vencimiento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.venc_fecha),expression:"form.venc_fecha"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.venc_fecha.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"date","disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.venc_fecha)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "venc_fecha", $event.target.value)},function($event){return _vm.validaLimiteSolicitud()}]}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Hora Vencimiento")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:({
                            mask: '99:99',
                            hourFormat: '24',
                          }),expression:"{\n                            mask: '99:99',\n                            hourFormat: '24',\n                          }"},{name:"model",rawName:"v-model",value:(_vm.form.venc_hora),expression:"form.venc_hora"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.venc_hora.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.venc_hora)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "venc_hora", $event.target.value)},function($event){return _vm.validaLimiteSolicitud()}]}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.estado),expression:"form.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.estado.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"disabled":_vm.form.estado == 2},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.save()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.estados),function(estado){return _c('option',{key:estado.id,attrs:{"disabled":estado.numeracion === 2 && _vm.accion == 'Crear'},domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)])]),_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Nominación")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                            _vm.$v.nominacion.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                          ],attrs:{"label":"nombre","options":_vm.listasForms.nominaciones,"placeholder":"Nominacion","disabled":_vm.form.estado == 2},on:{"input":function($event){return _vm.getSelectNominacion()}},model:{value:(_vm.nominacion),callback:function ($$v) {_vm.nominacion=$$v},expression:"nominacion"}})],1)]),(
                        _vm.$store.getters.can(
                          'hidrocarburos.solicitudes.subirSolicitudManual'
                        )
                      )?_c('div',{staticClass:"form-group col-md-3 text-center"},[_c('label',{attrs:{"for":"solicitud_manual"}},[_vm._v("Solicitud Manual")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.solicitud_manual),expression:"form.solicitud_manual"}],staticClass:"form-control form-control-sm p-0",attrs:{"type":"checkbox","disabled":_vm.form.id},domProps:{"checked":Array.isArray(_vm.form.solicitud_manual)?_vm._i(_vm.form.solicitud_manual,null)>-1:(_vm.form.solicitud_manual)},on:{"change":function($event){var $$a=_vm.form.solicitud_manual,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "solicitud_manual", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "solicitud_manual", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "solicitud_manual", $$c)}}}})]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Observación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observaciones),expression:"form.observaciones"}],staticClass:"form-control form-control-sm",attrs:{"disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observaciones", $event.target.value)}}})])])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[(
                        _vm.$store.getters.can('hidrocarburos.solicitudes.index')
                      )?_c('button',{staticClass:"btn bg-secondary",class:_vm.accion == 'Editar' ? 'col-md-2' : 'col-md-1',on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")]):_vm._e(),(
                        (_vm.$store.getters.can(
                          'hidrocarburos.solicitudes.create'
                        ) ||
                          _vm.$store.getters.can(
                            'hidrocarburos.solicitudes.edit'
                          )) &&
                          _vm.id == undefined
                      )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn bg-primary",class:_vm.accion == 'Editar' ? 'col-md-2' : 'col-md-1',attrs:{"disabled":_vm.form.estado == 2},on:{"click":function($event){_vm.cargando = true;
                        _vm.save();}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]):_vm._e(),(
                        _vm.$store.getters.can(
                          'hidrocarburos.solicitudes.index'
                        ) && _vm.form.estado == 2
                      )?_c('button',{staticClass:"btn bg-navy",on:{"click":function($event){_vm.cargando = true;
                        _vm.enviarCorreo();}}},[_c('i',{staticClass:"fas fa-share-square"}),_c('br'),_vm._v("Enviar correo ")]):_vm._e()])])])]),(this.accion == 'Editar')?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(this.accion == 'Editar')?_c('solicitud-calendar',{ref:"compSolicitudDiariaCalendar"}):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[(
                      _vm.$store.getters.can(
                        'hidrocarburos.adicionalesSolicitudes.create'
                      ) &&
                        _vm.form.adicionales_pendientes > 0 &&
                        (!(
                          _vm.form.det_solicitudes_diarias &&
                          _vm.form.det_solicitudes_diarias == ''
                        ) ||
                          _vm.form.det_solicitudes_diarias.length ==
                            _vm.cantidades_empresas.totalLarga +
                              _vm.cantidades_empresas.totalCorta) &&
                        _vm.form.det_programacion_sitio.cargues_ruta_corta -
                          _vm.cantidades_empresas.totalCorta >
                          0
                    )?_c('button',{staticClass:"btn btn-warning",attrs:{"data-toggle":"modal","data-target":"#Modal_adicional"},on:{"click":function($event){return _vm.$refs.compSolicitudAdicional.init(
                        true,
                        1,
                        _vm.form.det_programacion_sitio.cargues_ruta_corta -
                          _vm.cantidades_empresas.totalCorta
                      )}}},[_vm._v(" Adicionales Pendientes Ruta corta "),_c('span',{staticClass:"badge badge-dark"},[_vm._v(" "+_vm._s(_vm.form.det_programacion_sitio.cargues_ruta_corta - _vm.cantidades_empresas.totalCorta)+" ")])]):_vm._e()]),_c('div',{staticClass:"col-md-3"},[(
                      _vm.$store.getters.can(
                        'hidrocarburos.adicionalesSolicitudes.create'
                      ) &&
                        _vm.form.adicionales_pendientes > 0 &&
                        (!(
                          _vm.form.det_solicitudes_diarias &&
                          _vm.form.det_solicitudes_diarias == ''
                        ) ||
                          (_vm.form.det_solicitudes_diarias.length ==
                            _vm.cantidades_empresas.totalLarga +
                              _vm.cantidades_empresas.totalCorta &&
                            _vm.form.det_solicitudes_diarias.length > 0)) &&
                        _vm.form.det_programacion_sitio.cargues_ruta_larga -
                          _vm.cantidades_empresas.totalLarga >
                          0
                    )?_c('button',{staticClass:"btn btn-warning",attrs:{"data-toggle":"modal","data-target":"#Modal_adicional"},on:{"click":function($event){return _vm.$refs.compSolicitudAdicional.init(
                        true,
                        2,
                        _vm.form.det_programacion_sitio.cargues_ruta_larga -
                          _vm.cantidades_empresas.totalLarga
                      )}}},[_vm._v(" Adicionales Pendientes Ruta larga "),_c('span',{staticClass:"badge badge-dark"},[_vm._v(" "+_vm._s(_vm.form.det_programacion_sitio.cargues_ruta_larga - _vm.cantidades_empresas.totalLarga)+" ")])]):_vm._e()])])]):_vm._e()]),(this.accion == 'Editar')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(this.accion == 'Editar' && this.sitio != {})?_c('solicitud-empresas',{ref:"compSolicitudDiariaEmpresas"}):_vm._e()],1)]):_vm._e()]),(_vm.form.estado == 2 || _vm.form.adicionales_pendientes > 0)?_c('solicitud-adicional',{ref:"compSolicitudAdicional"}):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }