<template>
  <div class="row">
    <!-- Modal de standby -->
    <div class="modal fade" id="Modal_adicional">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Adicionales - Disminución</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- Cuerpo del modal -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="form-group col-md-12">
                    <label>Tipo de ajuste</label>
                    <select
                      v-model="form.tipo_ajuste"
                      class="form-control form-control-sm p-0"
                      :class="
                        $v.form.tipo_ajuste.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @change="calcCantidad()"
                      :disabled="pendiente"
                    >
                      <option value="">Seleccione</option>
                      <option value="1">Adicional</option>
                      <option value="2">Disminución</option>
                      <option value="3" v-if="tiempoOferta">
                        Oferta Adicionales
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-6" v-if="form.tipo_ajuste == 3">
                    <label>Campo</label>
                    <input
                      type="checkbox"
                      class="form-control form-control-sm"
                      v-model="form.campo"
                      @click="getEmpresasCampo()"
                      :disabled="form.nacional"
                    />
                  </div>

                  <div class="form-group col-md-6" v-if="form.tipo_ajuste == 3">
                    <label>Nacional</label>
                    <input
                      type="checkbox"
                      class="form-control form-control-sm"
                      v-model="form.nacional"
                      @click="getEmpresasNacional()"
                      :disabled="form.campo"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.adicionalesSolicitudes.nacionales'
                        )
                      "
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label>Volumen</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <input
                            type="checkbox"
                            v-model="form.checlVol"
                            @change="cambiarLista()"
                            :disabled="pendiente"
                          />
                        </span>
                      </div>
                      <input
                        type="number"
                        min="1"
                        class="form-control form-control-sm"
                        v-model="form.volumen"
                        :readonly="!form.checlVol"
                        :class="
                          $v.form.cantidad.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @change="calcCantidad()"
                      />
                    </div>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Cantidad</label>
                    <input
                      type="number"
                      min="1"
                      class="form-control form-control-sm"
                      v-model="form.cantidad"
                      :readonly="form.checlVol"
                      :disabled="pendiente"
                      :class="
                        $v.form.cantidad.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>

                  <div class="form-group col-md-6" v-if="form.checlVol">
                    <label>Producto Líquido</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="form.producto_liquido"
                      label="nombre"
                      :options="listasForms.productos_liquidos"
                      placeholder="Producto Líquido"
                      @input="getSelectProducto()"
                      class="form-control form-control-sm p-0"
                    >
                    </v-select>
                  </div>

                  <div class="form-group col-md-6" v-if="form.checlVol">
                    <label>Destino</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="form.destino"
                      label="nombre"
                      :options="listasForms.destinos"
                      placeholder="Destinos"
                      @input="getSelectDestino()"
                      class="form-control form-control-sm p-0"
                    >
                    </v-select>
                  </div>

                  <div
                    class="form-group col-md-6"
                    v-if="form.tipo_ajuste == 1 || form.tipo_ajuste == 3"
                  >
                    <label>Fecha</label>
                    <input
                      type="Date"
                      min="1"
                      class="form-control form-control-sm"
                      v-model="form.fecha"
                      :class="
                        $v.form.fecha.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>

                  <div
                    class="form-group col-md-6"
                    v-if="form.tipo_ajuste == 1 || form.tipo_ajuste == 3"
                  >
                    <label>Hora</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      min="1"
                      v-model="form.hora"
                      :class="$v.form.hora.$invalid ? 'is-invalid' : 'is-valid'"
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label>Justificación</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.justificacion"
                      :class="
                        $v.form.justificacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tr in listasForms.lista_ajuste"
                        :key="tr.id"
                        :value="tr.numeracion"
                      >
                        {{ tr.descripcion }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Tipo Ruta</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.tipo_ruta"
                      @change="calcCantidad()"
                      :disabled="form.checlVol === true || pendiente"
                      :class="
                        $v.form.tipo_ruta.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tr in listasForms.tipo_rutas"
                        :key="tr.id"
                        :value="tr.numeracion"
                      >
                        {{ tr.descripcion }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-6" v-if="form.tipo_ajuste == 3">
                    <label for="">Tipo Vehiculo</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.tipo_vehiculo_id"
                      :class="
                        $v.form.tipo_vehiculo_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="op in listasForms.tipo_vehiculo"
                        :key="op.id"
                        :value="op.id"
                      >
                        {{ op.nombre }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-6" v-if="form.tipo_ajuste == 3">
                    <label for="">Tipo Producto</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.tipo_producto_id"
                      :class="
                        $v.form.tipo_producto_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="producto in listasForms.tipo_producto"
                        :key="producto.id"
                        :value="producto.id"
                      >
                        {{ producto.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6" v-if="form.tipo_ajuste == 3">
                    <label>Producto Liquido Adicional</label>
                    <v-select
                      :class="[
                        $v.form.product_liquido.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="form.product_liquido"
                      label="nombre"
                      :options="listasForms.product_liquidos"
                      placeholder="Producto Líquido"
                      @search="buscarProductoLiquido"
                      @input="SelectProductoLiquido()"
                      class="form-control form-control-sm p-0"
                    >
                    </v-select>
                  </div>
                </div>
              </div>

              <div v-if="form.nacional" class="col-md-12">
                <ul class="list-group">
                  <li
                    class="list-group-item"
                    v-for="(empresa, index) in listasForms.empresas_nacional"
                    :key="index"
                  >
                    {{ index + 1 }}.
                    {{ empresa.empresa.razon_social }}
                  </li>
                </ul>
              </div>

              <div v-if="form.campo" class="col-md-12">
                <ul class="list-group">
                  <li
                    class="list-group-item"
                    v-for="(empresa, index) in listasForms.empresas_campo"
                    :key="index"
                  >
                    {{ index + 1 }}.
                    {{ empresa.empresa.razon_social }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="
                $store.getters.can(
                  'hidrocarburos.adicionalesSolicitudes.create'
                ) && !$v.form.$invalid
              "
              @click="save"
            >
              Solicitar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { required, helpers } from "vuelidate/lib/validators";
import vSelect from "vue-select";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "SolicitudAdicional",
  components: {
    vSelect,
  },
  data() {
    return {
      ruta: [],
      form: {
        id: null,
        tipo_ajuste: null,
        solicitud_id: null,
        sitio_id: null,
        nominacion_id: null,
        cantidad: null,
        justificacion: null,
        fecha: null,
        hora: null,
        tipo_ruta: null,
        checlVol: false,
        volumen: null,
        producto_liquido: null,
        producto_liquido_id: null,
        product_liquido_id: null,
        destino: null,
        destino_id: null,
        detProgramacionSitio: null,
        empresasNal: [],
        empresasCampo: [],
        tipo_producto_id: [],
        tipo_vehiculo_id: [],
        product_liquido: [],
      },
      listasForms: {
        tipo_rutas: [],
        productos_liquidos: [],
        destinos: [],
        lista_ajuste: [],
        empresas_nacional: [],
        empresas_campo: [],
        tipo_vehiculo: [],
        tipo_producto: [],
        product_liquidos: [],
      },
      pendiente: false,
      tiempoOferta: false,
    };
  },
  validations() {
    if (this.form.tipo_ajuste == 1) {
      return {
        form: {
          tipo_ajuste: {
            required,
          },
          solicitud_id: {
            required,
          },
          sitio_id: {
            required,
          },
          cantidad: {
            required,
          },
          justificacion: {
            required,
          },
          fecha: {
            required,
          },
          hora: {
            required,
            timeFormat,
          },
          tipo_ruta: {
            required,
          },
        },
      };
    } else if (this.form.tipo_ajuste == 3) {
      return {
        form: {
          tipo_ajuste: {
            required,
          },
          solicitud_id: {
            required,
          },
          sitio_id: {
            required,
          },
          cantidad: {
            required,
          },
          justificacion: {
            required,
          },
          fecha: {
            required,
          },
          hora: {
            required,
            timeFormat,
          },
          tipo_ruta: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
          tipo_producto_id: {
            required,
          },
          product_liquido: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          tipo_ajuste: {
            required,
          },
          solicitud_id: {
            required,
          },
          sitio_id: {
            required,
          },
          cantidad: {
            required,
          },
          justificacion: {
            required,
          },
          tipo_ruta: {
            required,
          },
        },
      };
    }
  },

  methods: {
    init(pendiente, tipo_ruta, cant) {
      let data = this.$parent.form;
      /* let hora = moment().format("HH:mm:ss");
      let fecha = moment().format("YYYY-MM-DD"); */
      this.form = {
        id: null,
        solicitud_id: data.id,
        nominacion_id: this.$parent.form.nominacion_id,
        sitio_id: data.sitio_id,
        cantidad: null,
        justificacion: null,
        fecha_solicitud: data.fecha,
        fecha: null,
        hora: null,
        tipo_ruta: null,
      };
      if (pendiente) {
        this.form.cantidad = cant;
        this.form.tipo_ajuste = 1;
        (this.form.tipo_ruta = tipo_ruta), this.getListaAdicional(3);
      }

      this.pendiente = pendiente;

      this.getProductosLiquidos(data.sitio_id);
      this.getDestinos(data.sitio_id);
      this.getTipoRutas();
      this.getTipoVehiculo();
      this.valOptOferta();
    },

    /*     getTipoVehiculo() {
      axios.get("/api/admin/tiposVehiculos/lista").then((response) => {
        this.listasForms.tipo_vehiculo = response.data;
      });
    }, */

    getTipoVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipo_vehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoRutas() {
      this.listasForms.tipo_rutas = [];
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipo_rutas = response.data;
      });
    },

    getListaAdicional(cod_alterno) {
      axios
        .post("/api/lista/filtros", {
          params: { cod_alterno: cod_alterno, lista: 70 },
        })
        .then((response) => {
          this.listasForms.lista_ajuste = response.data;
        });
    },

    getListaDisminucion(cod_alterno) {
      axios
        .post("/api/lista/filtros", {
          params: { cod_alterno: cod_alterno, lista: 71 },
        })
        .then((response) => {
          this.listasForms.lista_ajuste = response.data;
        });
    },

    valOptOferta() {
      if (this.$parent.form.ajuste_cargue_solicitud.length > 0) {
        let ahora = new Date();

        let fecha_sol_add = new Date(
          this.$parent.form.ajuste_cargue_solicitud[0].created_at
        );

        fecha_sol_add.setMinutes(fecha_sol_add.getMinutes() + 15);

        if (fecha_sol_add < ahora) {
          this.tiempoOferta = true;
        }
      }
    },

    async cambiarLista() {
      //elegirLista

      if (
        this.form.tipo_ajuste == 1 ||
        this.form.tipo_ajuste == 2 ||
        this.form.tipo_ajuste == 3
      ) {
        if (this.form.tipo_ajuste == 1 || this.form.tipo_ajuste == 3) {
          if (this.form.checlVol) {
            this.getListaAdicional(1);
          } else {
            this.getListaAdicional(2);
          }
        } else {
          if (this.form.checlVol) {
            this.getListaDisminucion(1);
          } else {
            this.getListaDisminucion(2);
          }
        }
      }
    },

    getProductosLiquidos(sitio_id) {
      axios
        .get("/api/hidrocarburos/Productos/lista", {
          params: { sitio_id },
        })
        .then((response) => {
          this.listasForms.productos_liquidos = response.data;
        });
    },

    getTipoProducto() {
      axios.get("/api/admin/productos/lista").then((response) => {
        this.listasForms.tipo_producto = response.data;
      });
    },

    async getSelectProducto() {
      this.form.producto_liquido_id = null;
      if (this.form.producto_liquido) {
        this.form.producto_liquido_id = this.form.producto_liquido.id;
        this.calcCantidad();
      }

      await this.seleccionarRuta();
    },

    SelectProductoLiquido() {
      this.form.product_liquido_id = null;
      if (this.form.product_liquido) {
        this.form.product_liquido_id = this.form.product_liquido.id;
      }
    },

    buscarProductoLiquido(search) {
      let me = this;
      me.listasForms.product_liquidos = [];
      if (search != "") {
        let url =
          "api/hidrocarburos/Productos/lista?producto_liquido=" + search;
        axios
          .get(url)
          .then((response) => {
            me.listasForms.product_liquidos = response.data;
          })
          .catch((e) => {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    seleccionarRuta() {
      if (
        this.form.producto_liquido_id !== null &&
        this.form.destino_id != null
      ) {
        axios
          .post("/api/hidrocarburos/ajusteCarguesSolicitudes/buscarRuta", {
            params: {
              origen_id: this.$parent.form.sitio_id,
              destino_id: this.form.destino_id,
            },
          })
          .then((response) => {
            if (response.data.msg) {
              this.$swal({
                icon: "error",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.ruta = response.data;
              this.form.tipo_ruta = this.ruta.tipo_ruta;
            }
          });
      }
    },

    getDestinos(sitio_id) {
      axios
        .get("/api/admin/rutas/lista", {
          params: { origen_id: sitio_id },
        })
        .then((response) => {
          let data = response.data;
          data.forEach((element) => {
            this.listasForms.destinos.push({
              id: element.destino.id,
              nombre: element.destino.nombre,
            });
          });
        });
    },

    async getSelectDestino() {
      this.form.destino_id = null;
      if (this.form.destino) {
        this.form.destino_id = this.form.destino.id;
        this.calcCantidad();
      }
      await this.seleccionarRuta();
    },

    getEmpresasNacional() {
      this.empCheckNal = [];
      this.listasForms.empresas_nacional = [];
      this.$parent.cargando = true;
      axios
        .get(
          "/api/hidrocarburos/solicitudes/getEmpresasNacional/" +
            this.$parent.nominacion.id +
            "/" +
            this.$parent.sitio.id
        )
        .then((response) => {
          this.listasForms.empresas_nacional = response.data;

          for (let i = 0; i < response.data.length; i++) {
            this.empCheckNal[i] = true;
          }
          this.$parent.cargando = false;
        });
    },

    getEmpresasCampo() {
      this.empCheckCampo = [];
      this.listasForms.empresas_campo = [];
      this.$parent.cargando = true;
      axios
        .get(
          "/api/hidrocarburos/solicitudes/getEmpresasCampo/" +
            this.$parent.nominacion.id +
            "/" +
            this.$parent.sitio.id
        )
        .then((response) => {
          this.listasForms.empresas_campo = response.data;

          for (let i = 0; i < response.data.length; i++) {
            this.empCheckCampo[i] = true;
          }
          this.$parent.cargando = false;
        });
    },

    async calcCantidad() {
      await this.cambiarLista();
      if (
        this.form.destino_id != null &&
        this.form.producto_liquido_id != null &&
        this.form.volumen != null &&
        this.form.tipo_ruta != null &&
        this.form.tipo_ajuste != null
      ) {
        axios
          .get(
            "/api/hidrocarburos/ajusteCarguesSolicitudes/calcProgramacionVol",
            {
              params: this.form,
            }
          )
          .then((response) => {
            if (response.data.msg == "OK") {
              this.form.cantidad = response.data.cargues;
              this.form.detProgramacionSitio =
                response.data.detProgramacionSitio;
            } else {
              this.$swal({
                icon: "error",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          });
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.$parent.cargando = true;
        let rutaURI = "/api/hidrocarburos/ajusteCarguesSolicitudes";
        if (this.form.tipo_ajuste == 2) {
          rutaURI = rutaURI + "/saveDism";
        }
        if (this.form.tipo_ajuste == 3) {
          rutaURI = "/api/hidrocarburos/ofertaAdicional";
        }
        this.form.pendiente = this.pendiente;

        if (this.form.nacional) {
          let empNal = [];
          for (let i = 0; i < this.listasForms.empresas_nacional.length; i++) {
            if (this.empCheckNal[i]) {
              let ibjEmpNal = {
                empresa_id: this.listasForms.empresas_nacional[i].empresa.id,
                nacional: this.listasForms.empresas_nacional[i].nal,
              };
              empNal.push(ibjEmpNal);
            }
          }
          this.form.empresasNal = empNal;
        }

        if (this.form.campo) {
          let empCampo = [];
          for (let i = 0; i < this.listasForms.empresas_campo.length; i++) {
            if (this.empCheckCampo[i]) {
              let ibjEmpCampo = {
                empresa_id: this.listasForms.empresas_campo[i].empresa.id,
                nacional: 0,
              };
              empCampo.push(ibjEmpCampo);
            }
          }
          this.form.empresasCampo = empCampo;
        }

        let ahora = new Date();
        ahora.setMinutes(ahora.getMinutes() + 15);

        let fecha_venc_add = new Date(this.form.fecha + " " + this.form.hora);

        let val_fecha_venc = fecha_venc_add >= ahora;
        if (val_fecha_venc || this.form.tipo_ajuste == 2) {
          axios({
            method: "POST",
            url: rutaURI,
            data: this.form,
          })
            .then((response) => {
              if (response.data.msg == "OK") {
                this.$refs.closeModal.click();
                this.$parent.$refs.compSolicitudDiariaEmpresas.getDetSolicitudEmpresas();
                this.form = {
                  id: null,
                  tipo_ajuste: null,
                  solicitud_id: null,
                  sitio_id: null,
                  nominacion_id: null,
                  cantidad: null,
                  justificacion: null,
                  fecha: null,
                  hora: null,
                  tipo_ruta: null,
                  checlVol: false,
                  volumen: null,
                  producto_liquido: null,
                  producto_liquido_id: null,
                  destino: null,
                  destino_id: null,
                  detProgramacionSitio: null,
                };
                this.$swal({
                  icon: "success",
                  title: "Se ha solicitado el ajuste con éxito...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: response.data.msg,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }

              this.$parent.cargando = false;
            })
            .catch(() => {
              this.$parent.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ha ocurrido un error, intentelo nuevamente",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        } else {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "El plazo minimo para adicionales son 30 minutos",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },
  },
  mounted() {
    this.init();
    this.getTipoProducto();
    this.SelectProductoLiquido();
  },
};
</script>
