<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3">
                <h5 class="mb-0">
                  {{ accion }} Solicitud: <b v-if="id">{{ id }}</b>
                </h5>
              </div>
              <div class="col-md-1">
                <button
                  class="btn btn-sm bg-navy"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.adicionalesSolicitudes.create'
                    ) &&
                      form.estado == 2 &&
                      val_fecha
                  "
                  data-toggle="modal"
                  data-target="#Modal_adicional"
                  @click="$refs.compSolicitudAdicional.init(false)"
                >
                  <small>Ajuste Cargue</small>
                </button>
              </div>

              <div class="col-md-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div :class="accion == 'Editar' ? 'col-md-6' : 'col-md-12'">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Sitio</label>
                          <v-select
                            :class="[
                              $v.sitio.$invalid ? 'is-invalid' : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="sitio"
                            label="nombre"
                            :options="listasForms.sitios"
                            placeholder="Sitio"
                            @input="getSelectSitio()"
                            :disabled="form.estado == 2"
                            class="form-control form-control-sm p-0"
                          >
                          </v-select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Fecha Solicitud</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form.fecha"
                            :class="
                              $v.form.fecha.$invalid ? 'is-invalid' : 'is-valid'
                            "
                            :disabled="form.estado == 2"
                            @input="validaLimiteSolicitud()"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Fecha Vencimiento</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form.venc_fecha"
                            :class="
                              $v.form.venc_fecha.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="form.estado == 2"
                            @input="validaLimiteSolicitud()"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Hora Vencimiento</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            :class="
                              $v.form.venc_hora.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            v-mask="{
                              mask: '99:99',
                              hourFormat: '24',
                            }"
                            :disabled="form.estado == 2"
                            v-model="form.venc_hora"
                            @input="validaLimiteSolicitud()"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Estado</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.estado"
                            :class="
                              $v.form.estado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @change="save()"
                            :disabled="form.estado == 2"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.id"
                              :value="estado.numeracion"
                              :disabled="
                                estado.numeracion === 2 && accion == 'Crear'
                              "
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <div class="form-group">
                          <label for="">Nominación</label>
                          <v-select
                            :class="[
                              $v.nominacion.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="nominacion"
                            label="nombre"
                            :options="listasForms.nominaciones"
                            placeholder="Nominacion"
                            @input="getSelectNominacion()"
                            :disabled="form.estado == 2"
                            class="form-control form-control-sm p-0"
                          >
                          </v-select>
                        </div>
                      </div>
                      <div
                        class="form-group col-md-3 text-center"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.solicitudes.subirSolicitudManual'
                          )
                        "
                      >
                        <label for="solicitud_manual">Solicitud Manual</label>
                        <input
                          type="checkbox"
                          class="form-control form-control-sm p-0"
                          v-model="form.solicitud_manual"
                          :disabled="form.id"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Observación</label>
                          <textarea
                            :disabled="form.estado == 2"
                            v-model="form.observaciones"
                            class="form-control form-control-sm"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <button
                        class="btn bg-secondary"
                        :class="accion == 'Editar' ? 'col-md-2' : 'col-md-1'"
                        @click="back()"
                        v-if="
                          $store.getters.can('hidrocarburos.solicitudes.index')
                        "
                      >
                        <i class="fas fa-reply"></i><br />Volver
                      </button>
                      <button
                        class="btn bg-primary"
                        :class="accion == 'Editar' ? 'col-md-2' : 'col-md-1'"
                        v-show="!$v.form.$invalid"
                        @click="
                          cargando = true;
                          save();
                        "
                        :disabled="form.estado == 2"
                        v-if="
                          ($store.getters.can(
                            'hidrocarburos.solicitudes.create'
                          ) ||
                            $store.getters.can(
                              'hidrocarburos.solicitudes.edit'
                            )) &&
                            id == undefined
                        "
                      >
                        <i class="fas fa-paper-plane"></i><br />Guardar
                      </button>
                      <button
                        class="btn bg-navy"
                        @click="
                          cargando = true;
                          enviarCorreo();
                        "
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.solicitudes.index'
                          ) && form.estado == 2
                        "
                      >
                        <i class="fas fa-share-square"></i><br />Enviar correo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="this.accion == 'Editar'" class="col-md-6">
                <div class="card">
                  <div class="card-body">
                    <solicitud-calendar
                      ref="compSolicitudDiariaCalendar"
                      v-if="this.accion == 'Editar'"
                    ></solicitud-calendar>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <button
                      class="btn btn-warning"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.adicionalesSolicitudes.create'
                        ) &&
                          form.adicionales_pendientes > 0 &&
                          (!(
                            form.det_solicitudes_diarias &&
                            form.det_solicitudes_diarias == ''
                          ) ||
                            form.det_solicitudes_diarias.length ==
                              cantidades_empresas.totalLarga +
                                cantidades_empresas.totalCorta) &&
                          form.det_programacion_sitio.cargues_ruta_corta -
                            cantidades_empresas.totalCorta >
                            0
                      "
                      data-toggle="modal"
                      data-target="#Modal_adicional"
                      @click="
                        $refs.compSolicitudAdicional.init(
                          true,
                          1,
                          form.det_programacion_sitio.cargues_ruta_corta -
                            cantidades_empresas.totalCorta
                        )
                      "
                    >
                      Adicionales Pendientes Ruta corta
                      <span class="badge badge-dark">
                        {{
                          form.det_programacion_sitio.cargues_ruta_corta -
                            cantidades_empresas.totalCorta
                        }}
                      </span>
                    </button>
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-warning"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.adicionalesSolicitudes.create'
                        ) &&
                          form.adicionales_pendientes > 0 &&
                          (!(
                            form.det_solicitudes_diarias &&
                            form.det_solicitudes_diarias == ''
                          ) ||
                            (form.det_solicitudes_diarias.length ==
                              cantidades_empresas.totalLarga +
                                cantidades_empresas.totalCorta &&
                              form.det_solicitudes_diarias.length > 0)) &&
                          form.det_programacion_sitio.cargues_ruta_larga -
                            cantidades_empresas.totalLarga >
                            0
                      "
                      data-toggle="modal"
                      data-target="#Modal_adicional"
                      @click="
                        $refs.compSolicitudAdicional.init(
                          true,
                          2,
                          form.det_programacion_sitio.cargues_ruta_larga -
                            cantidades_empresas.totalLarga
                        )
                      "
                    >
                      Adicionales Pendientes Ruta larga
                      <span class="badge badge-dark">
                        {{
                          form.det_programacion_sitio.cargues_ruta_larga -
                            cantidades_empresas.totalLarga
                        }}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="this.accion == 'Editar'">
              <div class="col-md-12">
                <solicitud-empresas
                  ref="compSolicitudDiariaEmpresas"
                  v-if="this.accion == 'Editar' && this.sitio != {}"
                ></solicitud-empresas>
              </div>
            </div>
          </div>
          <solicitud-adicional
            v-if="form.estado == 2 || form.adicionales_pendientes > 0"
            ref="compSolicitudAdicional"
          ></solicitud-adicional>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Loading from "../../../../components/Loading";
import { required, helpers } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import SolicitudAdicional from "./SolicitudAdicional.vue";
import SolicitudEmpresas from "./SolicitudEmpresas.vue";
import SolicitudCalendar from "./SolicitudCalendar.vue";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "SolicitudForm",
  components: {
    Loading,
    vSelect,
    SolicitudAdicional,
    SolicitudEmpresas,
    SolicitudCalendar,
  },
  data() {
    return {
      nominacion: null,
      cargando: false,
      sitio: null,
      id: "",
      metodo: "",
      listasForms: {
        estados: [],
        sitios: [],
      },
      form: {
        sitio_id: null,
        fecha: null,
        fecha_venci_postulacion: null,
        nominacion_id: null,
        venc_fecha: null,
        venc_hora: null,
        solicitud_manual: false,
        estado: null,
      },
      accion: "",
      solicitudEmpresas: {},
      cantidades_empresas: {
        totalLarga: 0,
        totalCorta: 0,
      },
      val_fecha: false,
    };
  },
  validations: {
    form: {
      fecha: {
        required,
      },
      estado: {
        required,
      },
      venc_fecha: {
        required,
      },
      venc_hora: {
        required,
        timeFormat,
      },
    },
    sitio: {
      required,
    },
    nominacion: {
      required,
    },
  },
  methods: {
    enviarCorreo() {
      //consultar empresas
      let me = this;
      let arr = [];
      this.solicitudEmpresas = this.$refs.compSolicitudDiariaEmpresas.detSolicitudEmpresas;
      if (this.solicitudEmpresas !== null) {
        for (var i = 0; i < this.solicitudEmpresas.length; i++) {
          arr.push(this.solicitudEmpresas[i].empresa_id);
        }
      }

      axios
        .get("/api/hidrocarburos/solicitudes/enviarCorreo", {
          params: {
            solicitud_id: this.id,
            empresas: arr,
          },
        })
        .then(() => {
          me.$swal({
            icon: "success",
            title: "Correo enviado satisfactoriamente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Error, en el envío de correo:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getEstados() {
      this.cargando = true;
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
        this.cargando = false;
      });
    },

    getNominaciones() {
      this.cargando = true;
      axios.get("/api/hidrocarburos/nominacion/listFecha").then((response) => {
        this.listasForms.nominaciones = response.data;
        this.cargando = false;
      });
    },

    getSitios() {
      this.cargando = true;
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
        this.cargando = false;
        if (this.$route.params.accion == "Editar") {
          this.sitio = this.listasForms.sitios.find(
            (n) => n.id === this.form.sitio_id
          );
        }
      });
    },

    getSelectSitio() {
      if (this.sitio !== null) {
        this.form.sitio_id = this.sitio.id;
      } else {
        this.sitio = null;
        this.form.sitio_id = null;
      }
      this.validaLimiteSolicitud();
    },

    validaLimiteSolicitud() {
      let msg_error = null;
      const ahora = new Date();
      // Se valida que la fecha de solicitud nos sea menor a la fecha actual
      if (this.form.fecha) {
        let fecha_solicitud = new Date(
          `${
            this.form.fecha
          } ${ahora.getHours()}:${ahora.getMinutes()}:${ahora.getSeconds()}`
        );
        fecha_solicitud.setMinutes(fecha_solicitud.getMinutes() + 1);

        if (fecha_solicitud < ahora) {
          msg_error = `La fecha de solicitud se debe hacer a futuro`;
          this.form.fecha = null;
        }
      }

      // Se valida el contenido del formulario
      if (
        this.form.sitio_id &&
        this.form.fecha &&
        this.form.venc_fecha &&
        this.form.venc_hora
      ) {
        // Valido que la fecha de solicitud no sea mayor que la fecha de de vencimiento de la solicitud
        let fecha_menor = new Date(
          `${
            this.form.fecha
          } ${ahora.getHours()}:${ahora.getMinutes()}:${ahora.getSeconds()}`
        );
        let fecha_mayor = new Date(
          `${this.form.venc_fecha} ${this.form.venc_hora}`
        );

        if (fecha_menor > fecha_mayor) {
          this.form.venc_fecha = null;
          msg_error = `La fecha de solicitud no puede ser menor que la fecha de vencimiento de solicitud`;
        } else {
          // Se suma las horas limite a la fecha de solicitud
          fecha_menor.setHours(
            fecha_menor.getHours() +
              parseFloat(this.sitio.horas_limite_solicitud)
          );

          if (fecha_menor < fecha_mayor) {
            this.form.venc_hora = null;
            msg_error = `La fecha de vencimiento de solicitud supera la fecha límite parmitida para solicitar.`;
          }
        }
      }

      if (msg_error) {
        this.$swal({
          icon: "error",
          title: msg_error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    getSelectNominacion() {
      if (this.nominacion !== null) {
        this.form.nominacion_id = this.nominacion.id;
      } else {
        this.nominacion = null;
        this.form.nominacion_id = null;
      }
    },

    async save() {
      if (!this.$v.form.$invalid) {
        if (this.form.estado == 2) {
          if (
            (this.form.det_programacion_sitio.cargues_ruta_corta -
              this.cantidades_empresas.totalCorta >
              0 ||
              this.form.det_programacion_sitio.cargues_ruta_larga -
                this.cantidades_empresas.totalLarga >
                0) &&
            (this.form.solicitud_manual == 0 || !this.form.solicitud_manual)
          ) {
            this.form.estado = 1;
            await this.$swal({
              icon: "error",
              title:
                "No puede cambiar el estado hasta que solicite los adicionales pendientes",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          } else if (
            this.form.det_solicitudes_diarias.length == 0 ||
            this.form.det_solicitudes_diarias.length == ""
          ) {
            this.form.estado = 1;
            await this.$swal({
              icon: "error",
              title: "No puede cambiar el estado hasta que solicite las citas",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          } else {
            await this.$swal({
              title: "Esta seguro de poner en estado definitivo la Solicitud?",
              text: "No podra volver a editar la Solicitud",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Si, Definitivo",
            }).then((result) => {
              if (result.value) {
                this.cargando = true;
              } else {
                this.form.estado = 1;
              }
            });
          }
        }

        this.form.fecha_venci_postulacion =
          this.form.venc_fecha + " " + this.form.venc_hora;
        if (this.cargando) {
          axios({
            method: this.metodo,
            url: "/api/hidrocarburos/solicitudes",
            data: this.form,
          })
            .then((response) => {
              this.form = response.data;
              if (this.form.fecha_venci_postulacion != null) {
                let data_fecha_venc = this.form.fecha_venci_postulacion.split(
                  " "
                );
                this.form.venc_fecha = data_fecha_venc[0];
                this.form.venc_hora = data_fecha_venc[1];
              }
              this.id = this.form.id;
              this.accion = "Editar";
              this.metodo = "PUT";
              this.$swal({
                icon: "success",
                title: "La solicitud se guardo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
              if (this.form.estado == 2) {
                this.$refs.compSolicitudDiariaEmpresas.getDetSolicitudEmpresas();
              }
            })
            .catch((e) => {
              let res = Object.assign({}, e);
              this.$swal({
                icon: "error",
                title:
                  "Ocurrio un error, vuelva a intentarlo..." +
                  res.response.data.message,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            });
        }
      }
    },

    show() {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/solicitudes/" + this.id)
        .then((response) => {
          this.form = response.data;
          this.cargando = false;
          if (this.form.fecha_venci_postulacion != null) {
            let data_fecha_venc = this.form.fecha_venci_postulacion.split(" ");
            this.form.venc_fecha = data_fecha_venc[0];
            this.form.venc_hora = data_fecha_venc[1];
          }
        });
    },

    back() {
      return this.$router.replace("/Hidrocarburos/Solicitudes");
    },

    init() {
      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;
      if (this.id) {
        this.accion = "Editar";
        this.form = this.$route.params.data_edit;
        this.nominacion = this.$route.params.data_edit.nominacion;
        this.metodo = "PUT";
        if (this.form.fecha_venci_postulacion != null) {
          let data_fecha_venc = this.form.fecha_venci_postulacion.split(" ");
          this.form.venc_fecha = data_fecha_venc[0];
          this.form.venc_hora = data_fecha_venc[1];
        }
        this.val_fecha =
          moment(this.form.fecha + " 23:59:59").format("YYYY-MM-DD HH:MM:SS") >=
          moment().format("YYYY-MM-DD HH:MM:SS");
      } else {
        this.accion = "Crear";
        this.metodo = "POST";
      }
    },
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    this.getEstados();
    this.getNominaciones();
    this.getSitios();
  },
};
</script>
<style>
.gradient-buttons .btn {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.2) 49%,
    rgba(0, 0, 0, 0.15) 51%,
    rgba(0, 0, 0, 0.05)
  );
  background-repeat: repeat-x;
}

.card {
  margin: 10px;
  box-shadow: 0 5px 10px -2.5px;
}
</style>
